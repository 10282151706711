@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Be Vietnam Pro";
  src: local("Be Vietnam Pro"),
    url("./fonts/BeVietnamPro/BeVietnamPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: local("Be Vietnam Pro"),
    url("./fonts/BeVietnamPro/BeVietnamPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: local("Be Vietnam Pro"),
    url("./fonts/BeVietnamPro/BeVietnamPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: local("Be Vietnam Pro"),
    url("./fonts/BeVietnamPro/BeVietnamPro-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Be Vietnam Pro";
  src: local("Be Vietnam Pro"),
    url("./fonts/BeVietnamPro/BeVietnamPro-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Source Code Pro", monospace;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "ingra", sans-serif;
  font-style: normal;
}

/* @font-face {
  font-family: "ingra", sans-serif;
  font-style: normal;
}

@font-face {
  font-family: "ingra", sans-serif;
  font-style: normal;
}

@font-face {
  font-family: "ingra", sans-serif;
  font-style: normal;
}

@font-face {
  font-family: "ingra", sans-serif;
  font-style: normal;
} */

body {
  height: 100vh;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}
html {
  height: -webkit-fill-available;
}

#root {
  height: 100vh;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}
